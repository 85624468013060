import * as React from "react";
import Layout from "../components/Layout";
import Section from "../components/Section";
import Title, { TitleVariant } from "../components/Title";

const MentionsLegales = () => {
  return (
    <Layout
      title="Mentions légales et politique de confidentialité"
      metaTitle="Mentions légales et politique de confidentialité"
      description="Nos mentions légales ains que notre politique de confidentialité"
      banner="lg:bg-header-pattern"
    >
      <section className="mt-main">
        <p>
          La société DONUT PANIC, soucieuse des droits des individus, notamment
          au regard des traitements automatisés et dans une volonté de
          transparence avec ses clients, a mis en place une politique reprenant
          l’ensemble de ces traitements, des finalités poursuivies par ces
          derniers ainsi que des moyens d’actions à la disposition des individus
          afin qu’ils puissent au mieux exercer leurs droits.
        </p>
        <p>
          Pour toute information complémentaire sur la protection des données
          personnelles, nous vous invitons à consulter le site : 
          <a
            title="lien vers le site de la CNIL"
            href="https://www.cnil.fr/"
            className="underline text-primaryD1 font-poppins hover:text-primaryD2 lg:inline hover:no-underline"
          >
            https://www.cnil.fr/
          </a>
        </p>
        <p>
          La poursuite de la navigation sur ce site vaut acceptation sans
          réserve des dispositions et conditions d’utilisation qui suivent.
        </p>
        <p>
          La version actuellement en ligne de ces conditions d’utilisation est
          la seule opposable pendant toute la durée d’utilisation du site et
          jusqu’à ce qu’une nouvelle version la remplace.
        </p>
      </section>
      <Section title="Article 1 – Mentions légales">
        <Title
          variant={TitleVariant.h3}
          text="1.1 Site (ci-après « le site ») :"
          className="text-center mt-main"
        />
        <p className="mt-main">donutpanic.fr</p>

        <Title
          variant={TitleVariant.h3}
          text="1.2 Éditeur (ci-après « l’éditeur »)"
          className="text-center mt-main"
        />
        <p className="mt-main">
          DONUT PANIC, Société par Actions Simplifiées au capital de 6.000
          euros, dont le siège social est situé 21 place de la République, 75003
          Paris, immatriculée au R.C.S. de Paris sous le numéro 823 433 602.
          <br />
          Pour nous contacter : 
          <a
            title="ecrire un mail à donutpanic"
            href="mailto:hello@donutpanic.fr"
            className="underline text-primaryD1 font-poppins hover:text-primaryD2 lg:inline hover:no-underline"
          >
            hello@donutpanic.fr
          </a>
          <br />
          Directeur de la publication : M. Quentin LEDOUX, Président
        </p>

        <Title
          variant={TitleVariant.h3}
          text="1.3 Hébergeur (ci-après « l’hébergeur ») :"
          className="text-center mt-main"
        />
        <p className="mt-main">
          donutpanic.fr est hébergé par OVH, dont le siège social est situé 2
          rue Kellermann 59100 Roubaix.
        </p>
      </Section>
      <Section title="Article 2 – Accès au site">
        <p className="mt-main">
          L’accès au site et son utilisation sont réservés à un usage
          strictement personnel. Vous vous engagez à ne pas utiliser ce site et
          les informations ou données qui y figurent à des fins commerciales,
          politiques, publicitaires et pour toute forme de sollicitation
          commerciale et notamment l’envoi de courriers électroniques non
          sollicités.
        </p>
      </Section>
      <Section title="Article 3 – Contenu du site">
        <p className="mt-main">
          Toutes les marques, photographies, textes, commentaires,
          illustrations, images animées ou non, séquences vidéo, sons, ainsi que
          toutes les applications informatiques qui pourraient être utilisées
          pour faire fonctionner ce site et plus généralement tous les éléments
          reproduits ou utilisés sur le site sont protégés par les lois en
          vigueur au titre de la propriété intellectuelle.
        </p>
        <p>
          Ils sont la propriété pleine et entière de l’éditeur ou de ses
          partenaires. Toute reproduction, représentation, utilisation ou
          adaptation, sous quelque forme que ce soit, de tout ou partie de ces
          éléments, y compris les applications informatiques, sans l’accord
          préalable et écrit de l’éditeur, sont strictement interdites. Le fait
          pour l’éditeur de ne pas engager de procédure dès la prise de
          connaissance de ces utilisations non autorisées ne vaut pas
          acceptation desdites utilisations et renonciation aux poursuites.
        </p>
      </Section>
      <Section title="Article 4 – Gestion du site">
        <p className="mt-main">
          Pour la bonne gestion du site, l’éditeur pourra à tout moment :
        </p>
        <ul>
          <li>
            – suspendre, interrompre ou limiter l’accès à tout ou partie du
            site, réserver l’accès au site, ou à certaines parties du site, à
            une catégorie déterminée d’internautes ;
          </li>
          <li>
            – supprimer toute information pouvant en perturber le fonctionnement
            ou entrant en contravention avec les lois nationales ou
            internationales ;
          </li>
          <li>– suspendre le site afin de procéder à des mises à jour.</li>
        </ul>
      </Section>
      <Section title="Article 5 – Responsabilités">
        <p className="mt-main">
          La responsabilité de l’éditeur ne peut être engagée en cas de
          défaillance, panne, difficulté ou interruption de fonctionnement,
          empêchant l’accès au site ou à une de ses fonctionnalités.
        </p>
        <p>
          Le matériel de connexion au site que vous utilisez est sous votre
          entière responsabilité. Vous devez prendre toutes les mesures
          appropriées pour protéger votre matériel et vos propres données
          notamment d’attaques virales par Internet. Vous êtes par ailleurs seul
          responsable des sites et données que vous consultez.
        </p>
        <p>
          L’éditeur ne pourra être tenu responsable en cas de poursuites
          judiciaires à votre encontre :
        </p>
        <ul>
          <li>
            – du fait de l’usage du site ou de tout service accessible via
            Internet ;
          </li>
          <li>
            – du fait du non-respect par vous des présentes conditions
            générales.
          </li>
        </ul>
        <p>
          L’éditeur n’est pas responsable des dommages causés à vous-même, à des
          tiers et/ou à votre équipement du fait de votre connexion ou de votre
          utilisation du site et vous renoncez à toute action contre lui de ce
          fait.
        </p>
        <p>
          Si l’éditeur venait à faire l’objet d’une procédure amiable ou
          judiciaire en raison de votre utilisation du site, il pourra se
          retourner contre vous pour obtenir l’indemnisation de tous les
          préjudices, sommes, condamnations et frais qui pourraient découler de
          cette procédure.
        </p>
      </Section>
      <Section title="Article 6 – Liens hypertextes">
        <p className="mt-main">
          La mise en place par les utilisateurs de tous liens hypertextes vers
          tout ou partie du site est autorisée par l’éditeur. Tout lien devra
          être retiré sur simple demande de l’éditeur.
        </p>
        <p>
          Toute information accessible via un lien vers d’autres sites n’est pas
          publiée par l’éditeur. L’éditeur ne dispose d’aucun droit sur le
          contenu présent dans ledit lien.
        </p>
      </Section>
      <Section title="Article 7 – Collecte et protection des données">
        <p className="mt-main">
          Vos données sont collectées par la société DONUT PANIC.
        </p>
        <p>
          Une donnée à caractère personnel désigne toute information concernant
          une personne physique identifiée ou identifiable (personne concernée)
          ; est réputée identifiable une personne qui peut être identifiée,
          directement ou indirectement, notamment par référence à un nom, un
          numéro d’identification ou à un ou plusieurs éléments spécifiques,
          propres à son identité physique, physiologique, génétique, psychique,
          économique, culturelle ou sociale.
        </p>
        <p>
          Les informations personnelles pouvant être recueillies sur le site
          sont principalement utilisées par l’éditeur pour la gestion des
          relations avec vous, et le cas échéant pour le traitement de vos
          commandes.
        </p>
        <p>Les données personnelles collectées sont les suivantes :</p>
        <ul>
          <li>– nom et prénom</li>
          <li>– adresse mail</li>
          <li>– numéro de téléphone</li>
        </ul>
      </Section>
      <Section title="Article 8 – Droit d’accès, de rectification et de déréférencement de vos données">
        <p className="mt-main">
          Informatiques et libertés
          <br />
          Nous nous engageons à assurer le meilleur niveau de protection à vos
          données en conformité avec la loi Informatique et Libertés du 6
          janvier 1978 et Règlement (UE) 2016/679 du Parlement européen et du
          Conseil du 27 avril 2016 relatif à la protection des personnes
          physiques à l’égard du traitement des Données Personnelles à caractère
          personnel, Règlement Général sur la Protection des Données (RGPD).
        </p>
        <p className="mt-main">
          <b>
            Les informations nominatives vous concernant peuvent faire l’objet
            d’un traitement automatisé
          </b>
          . Les informations recueillies font l’objet d’un traitement
          informatique destiné à vous proposer des services susceptibles de vous
          intéresser. Les destinataires des données sont les services
          commerciaux et communication de Donut Panic.
        </p>
        <p className="mt-main">
          <strong>Conformément à la loi n° 78-17 du 6 janvier 1978,</strong>
          chaque internaute ayant déposé des informations nominatives le
          concernant sur les Sites dispose des droits d’opposition à la
          conservation (article 38 de la loi), d’accès (articles 39, 41 et 42 de
          la loi) et de rectification (article 40 de la loi) de ces données.
          Ainsi, il peut exiger que soient rectifiées, complétées, clarifiées,
          mises à jour ou effacées les informations le concernant qui sont
          inexactes, incomplètes, équivoques, périmées, ou dont la collecte ou
          l’utilisation, la communication ou la conservation est interdite.
        </p>
        <p>
          Chaque internaute peut exercer ces droits en écrivant à Donut Panic,
          21 Place de la République, 75003 Paris ou
          [hello@donutpanic.fr](mailto:hello@donutpanic.fr)
        </p>
        <p>
          Toute demande doit être accompagnée de la photocopie d’un titre
          d’identité en cours de validité signé et faire mention de l’adresse à
          laquelle l’éditeur pourra contacter le demandeur. La réponse sera
          adressée dans le mois suivant la réception de la demande. Ce délai
          d’un mois peut être prolongé de deux mois si la complexité de la
          demande et/ou le nombre de demandes l’exigent.
        </p>
        <p>
          De plus, et depuis la loi n°2016-1321 du 7 octobre 2016, les personnes
          qui le souhaitent, ont la possibilité d’organiser le sort de leurs
          données après leur décès. Pour plus d’information sur le sujet, vous
          pouvez consulter le site Internet de la CNIL : 
          <a
            title="lien vers le site de la CNIL"
            href="https://www.cnil.fr/"
            className="underline text-primaryD1 font-poppins hover:text-primaryD2 lg:inline hover:no-underline"
          >
            https://www.cnil.fr/
          </a>
        </p>
        <p>
          Les utilisateurs peuvent aussi introduire une réclamation auprès de la
          CNIL sur le site de la CNIL : 
          <a
            title="lien vers le site de la CNIL"
            href="https://www.cnil.fr/"
            className="underline text-primaryD1 font-poppins hover:text-primaryD2 lg:inline hover:no-underline"
          >
            https://www.cnil.fr/
          </a>
          .
        </p>
        <p>
          Nous vous recommandons de nous contacter dans un premier temps avant
          de déposer une réclamation auprès de la CNIL, car nous sommes à votre
          entière disposition pour régler votre problème.
        </p>
      </Section>
      <Section title="Article 9 – Utilisation des données">
        <p className="mt-main">
          Les données personnelles collectées auprès des utilisateurs ont pour
          objectif la mise à disposition des services de la Plateforme, leur
          amélioration et le maintien d’un environnement sécurisé. La base
          légale des traitements est l’exécution du contrat entre l’utilisateur
          et la Plateforme. Plus précisément, les utilisations sont les
          suivantes :
        </p>
        <ul>
          <li>– accès et utilisation de la Plateforme par l’utilisateur ;</li>
          <li>
            – gestion du fonctionnement et optimisation de la Plateforme ;
          </li>
          <li>– mise en œuvre d’une assistance utilisateurs ;</li>
          <li>
            – vérification, identification et authentification des données
            transmises par l’utilisateur ;
          </li>
          <li>
            – prévention et détection des fraudes, malwares (malicious softwares
            ou logiciels malveillants) et gestion des incidents de sécurité ;
          </li>
          <li>– gestion des éventuels litiges avec les utilisateurs ;</li>
        </ul>
      </Section>
      <Section title="Article 10 – Politique de conservation des données">
        <p className="mt-main">
          La Plateforme conserve vos données pour la durée nécessaire pour vous
          fournir ses services ou son assistance.
        </p>
        <p>
          Dans la mesure raisonnablement nécessaire ou requise pour satisfaire
          aux obligations légales ou réglementaires, régler des litiges,
          empêcher les fraudes et abus ou appliquer nos modalités et conditions,
          nous pouvons également conserver certaines de vos informations si
          nécessaire, même après que vous ayez fermé votre compte ou que nous
          n’ayons plus besoin pour vous fournir nos services.
        </p>
      </Section>
      <Section title="Article 11 - Cookies">
        <p className="mt-main">Pas de cookies.</p>
        <p>Notre site n'utilise pas de cookie, on ne vous tracke pas.</p>
        <p>Notre truc à nous c'est plutôt les 🍩 de toute façon.</p>
      </Section>
      <Section title="Article 12 – Loi applicable">
        <p className="mt-main">
          Les présentes conditions d’utilisation du site sont régies par la loi
          française et soumises à la compétence des tribunaux du siège social de
          l’éditeur, sous réserve d’une attribution de compétence spécifique
          découlant d’un texte de loi ou réglementaire particulier.
        </p>
      </Section>
      <Section title="Article 13 – Contactez-nous">
        <p className="mt-main">
          Pour toute question, information sur les produits présentés sur le
          site, ou concernant le site lui-même, vous pouvez laisser un message à
          l’adresse suivante :{" "}
          <a
            title="ecrire un mail à donutpanic"
            href="mailto:hello@donutpanic.fr"
            className="underline text-primaryD1 font-poppins hover:text-primaryD2 lg:inline hover:no-underline"
          >
            hello@donutpanic.fr
          </a>
        </p>
      </Section>
    </Layout>
  );
};

export default MentionsLegales;
